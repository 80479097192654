.upload {
    margin-top: 20px;
}

.upload img {
    margin: auto;
    width: 380px;
}

.upload input {
    display: block;
    margin: auto;
    color: transparent;
    width: 232px;
}

.custom-file-input {
    color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}
.custom-file-input::before {
    content: 'Subir comprobante (PNG, JPG)';
    color: black;
    display: inline-block;
    border: 2px solid #f4289a;
    border-radius: 4px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    margin: auto;
    /* font-weight: 700; */
    font-size: 11pt;
}
.custom-file-input:hover::before {
    border-color: black;
}
.custom-file-input:active {
    outline: 0;
}
.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
}

.upload div {
    width: auto;
    margin: 20px auto;
}

.upload button {
    background: #d31f7e;
    color: white;
    padding: 5px;
    border-radius: 5px;
    display: block;
    margin: auto;
    width: 233px;
    height: 36px;
    font-size: 11pt;
}
