@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background: #f1f5f9;
}

.text-xs {
    font-size: 0.7rem;
}